const navbar = document.getElementById('navbar');
const form = document.getElementById('form');
const name = document.getElementById('name');
const email = document.getElementById('email');
const phone = document.getElementById('phone');

// Navbar Effect
let scrolled = false;

window.onscroll = function () {
  if (window.pageYOffset > 100) {
    navbar.classList.remove('top');
    if (!scrolled) {
      navbar.style.transform = 'translateY(-70px)';
    }
    setTimeout(function () {
      navbar.style.transform = 'translateY(0)';
      scrolled = true;
    }, 200);
  } else {
    navbar.classList.add('top');
    scrolled = false;
  }
};

// Show Input Error Message
function showError(input, message) {
  const formControl = input.parentElement;
  formControl.className = 'form-control error';
  const small = formControl.querySelector('small');
  small.innerText = message;
}

// Show Success Outline
function showSuccess(input) {
  const formControl = input.parentElement;
  formControl.className = 'form-control success';
}

// Check if email is valid
function checkEmail(input) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = false;

  if (re.test(input.value.trim())) {
    showSuccess(input);
  } else {
    showError(input, 'Email is not valid.');
    error = true;
  }

  return error;
}

// Check Required Fields
function checkRequired(inputArr) {
  let errors = [];

  inputArr.forEach((input) => {
    if (input.value.trim() === '') {
      showError(input, `${getFieldName(input)} is required.`);
      errors.push(true);
    } else {
      showSuccess(input);
    }
  });

  return errors.length > 0 ? true : false;
}

// Check input length
function checkLength(input, min, max) {
  let error = false;

  if (input.value.trim().length < min) {
    showError(
      input,
      `${getFieldName(input)} must be at least ${min} characters long.`
    );
    error = true;
  } else if (input.value.trim().length > max) {
    showError(
      input,
      `${getFieldName(input)} must be less than ${max} characters long.`
    );
    error = true;
  } else {
    showSuccess(input);
  }

  return error;
}

// Get fieldname
function getFieldName(input) {
  return input.id.charAt(0).toUpperCase() + input.id.slice(1);
}

// Event Listeners
form.addEventListener('submit', (e) => {
  e.preventDefault();

  const requiredError = checkRequired([name, email, phone]);
  const nameLengthError = checkLength(name, 3, 70);
  const phoneLengthError = checkLength(phone, 8, 16);
  const emailError = checkEmail(email);

  if (!requiredError && !nameLengthError && !phoneLengthError && !emailError) {
    form.submit();
  }
});
